import React, { FC } from 'react';
import { useTheme } from '@mui/material';

interface HorizontalLogoProps {
  width?: number;
  color?: string;
}

export const HorizontalLogo: FC<HorizontalLogoProps> = ({ width, color }) => {
  const theme = useTheme();
  return (
    <svg
      viewBox='0 0 245 32'
      width={width || theme.spacing(31)}
      fill={color || theme.palette.text.primary}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M105.146 23.6024V11.0659L100.167 23.6024H98.9218L93.8858 11.0659V23.6024H91V7.33887H95.0175L99.4877 18.52L103.901 7.33887H107.919V23.6024H105.146Z' />
      <path d='M134.852 23.6024L133.664 20.44H126.195L125.007 23.6024H121.781L128.176 7.33887H131.74L138.134 23.6024H134.852ZM129.93 10.1624L126.987 17.8989H132.872L129.93 10.1624Z' />
      <path d='M163.258 23.6024L162.07 20.44H154.601L153.413 23.6024H150.188L156.582 7.33887H160.146L166.484 23.6024H163.258ZM158.336 10.1624L155.393 17.8989H161.278L158.336 10.1624Z' />
      <path d='M178.932 21.2871L180.573 19.0847C181.648 20.2706 183.459 21.3435 185.722 21.3435C188.042 21.3435 188.948 20.2141 188.948 19.0847C188.948 15.6965 179.441 17.7859 179.441 11.8C179.441 9.08941 181.761 7 185.383 7C187.872 7 189.966 7.84706 191.494 9.31529L189.853 11.4612C188.551 10.1624 186.797 9.59765 185.1 9.59765C183.459 9.59765 182.384 10.4447 182.384 11.6306C182.384 14.68 191.89 12.8165 191.89 18.8588C191.89 21.5694 189.966 23.8847 185.609 23.8847C182.554 23.8847 180.347 22.8118 178.932 21.2871Z' />
      <path d='M210.054 23.6024V9.82357H205.131V7.33887H217.863V9.82357H212.94V23.6024H210.054Z' />
      <path d='M241.514 23.6024L237.949 17.5036H235.064V23.6024H232.178V7.33887H239.364C242.589 7.33887 244.739 9.42828 244.739 12.4212C244.739 15.3012 242.816 16.8824 240.835 17.2212L244.853 23.6024H241.514ZM241.797 12.4212C241.797 10.84 240.609 9.88004 239.024 9.88004H235.064V15.0753H239.024C240.609 15.0189 241.797 14.0024 241.797 12.4212Z' />
      <path d='M16.4048 31.1502C15.6289 31.1502 15.0007 30.5233 15.0007 29.7489V1.42914C15.0007 0.839143 15.3702 0.322897 15.8875 0.101648C16.4417 -0.1196 17.0329 0.0278989 17.4394 0.470396L30.9997 15.1097L44.5599 0.470396C44.9664 0.0278989 45.5576 -0.0827252 46.1118 0.101648C46.666 0.322897 46.9986 0.839143 46.9986 1.42914V26.9096C46.9986 27.6839 46.3704 28.3108 45.5945 28.3108C44.8186 28.3108 44.1905 27.6839 44.1905 26.9096V5.04286L32.0342 18.1703C31.7756 18.4653 31.4061 18.6128 30.9997 18.6128C30.5932 18.6128 30.2237 18.4653 29.9651 18.1703L17.8458 5.04286V29.7489C17.8458 30.5233 17.1807 31.1502 16.4048 31.1502Z' />
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M1.40406 22.6691C0.628133 22.6691 0 22.0422 0 21.2679V16.806C0 16.0317 0.628133 15.4048 1.40406 15.4048C2.17999 15.4048 2.80813 16.0317 2.80813 16.806V21.2679C2.84507 22.0422 2.21694 22.6691 1.40406 22.6691Z'
        />
      </g>
      <g opacity='0.5'>
        <path
          opacity='0.5'
          d='M8.83131 26.3198C8.05538 26.3198 7.42725 25.6929 7.42725 24.9185V12.7499C7.42725 11.9755 8.05538 11.3486 8.83131 11.3486C9.60724 11.3486 10.2354 11.9755 10.2354 12.7499V24.8817C10.2354 25.656 9.60724 26.3198 8.83131 26.3198Z'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M23.6106 27.5367C22.8347 27.5367 22.2065 26.9098 22.2065 26.1355V20.0511C22.2065 19.2768 22.8347 18.6499 23.6106 18.6499C24.3865 18.6499 25.0147 19.2768 25.0147 20.0511V26.0986C25.0147 26.873 24.3865 27.5367 23.6106 27.5367Z'
        />
      </g>
      <g opacity='0.61'>
        <path
          opacity='0.61'
          d='M38.3897 27.5363C37.6137 27.5363 36.9856 26.9094 36.9856 26.1351V21.6732C36.9856 20.8988 37.6137 20.272 38.3897 20.272C39.1656 20.272 39.7937 20.8988 39.7937 21.6732V26.0982C39.8307 26.8725 39.2025 27.5363 38.3897 27.5363Z'
        />
      </g>
      <g opacity='0.4'>
        <path
          opacity='0.4'
          d='M53.2066 22.669C52.4306 22.669 51.8025 22.0421 51.8025 21.2677V10.7216C51.8025 9.94718 52.4306 9.32031 53.2066 9.32031C53.9825 9.32031 54.6106 9.94718 54.6106 10.7216V21.2308C54.6106 22.0421 53.9825 22.669 53.2066 22.669Z'
        />
      </g>
      <g opacity='0.69'>
        <path
          opacity='0.69'
          d='M60.5962 15.4046C59.8203 15.4046 59.1921 14.7778 59.1921 14.0034V7.91906C59.1921 7.14469 59.8203 6.51782 60.5962 6.51782C61.3721 6.51782 62.0003 7.14469 62.0003 7.91906V14.0034C62.0003 14.7409 61.3721 15.4046 60.5962 15.4046Z'
        />
      </g>
      <g opacity='0.7'>
        <path
          opacity='0.7'
          d='M31 29.1219C30.2241 29.1219 29.5959 28.4951 29.5959 27.7207V25.287C29.5959 24.5126 30.2241 23.8857 31 23.8857C31.7759 23.8857 32.4041 24.5126 32.4041 25.287V27.7207C32.441 28.4951 31.7759 29.1219 31 29.1219Z'
        />
      </g>
      <g opacity='0.54'>
        <path
          opacity='0.54'
          d='M31 8.50916C30.2241 8.50916 29.5959 7.88229 29.5959 7.10792V4.67419C29.5959 3.89982 30.2241 3.27295 31 3.27295C31.7759 3.27295 32.4041 3.89982 32.4041 4.67419V7.10792C32.441 7.88229 31.7759 8.50916 31 8.50916Z'
        />
      </g>
    </svg>
  );
};
