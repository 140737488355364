import { RefObject, useEffect } from "react"

export const useIntersectionObserver = (target: Element | RefObject<Element> | null, callback: IntersectionObserverCallback, options?: IntersectionObserverInit) => {
  const element = (target as RefObject<Element>)?.current || target as Element;
  useEffect(() => {
    if (!element || typeof IntersectionObserver === 'undefined') {
      return;
    }
    const observer = new IntersectionObserver(callback, options);
    observer.observe(element);
    return () => {
      observer.unobserve(element)
    }

  }, [element, callback, options])
  if (!element || typeof IntersectionObserver === 'undefined') {
    return false;
  }
}