import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Button,
  Box,
  ButtonProps,
  Hidden,
  Menu,
  IconButton,
  Toolbar,
  MenuItem
} from '@mui/material';
import { Close, Menu as MenuIcon } from '@mui/icons-material';
import { SpeedDialIcon } from '@mui/material';
import { HorizontalLogo } from 'common/components/HorizontalLogo';
import { Link } from 'common/components/Link';
import { useCallback, useState, MouseEvent } from 'react';
import { useIntersectionObserver } from 'common/hooks/useIntersectionObserver';

export interface AppBarProps {
  appBar?: MuiAppBarProps & {
    stickyColor?: MuiAppBarProps['color'];
    stickyElevation?: MuiAppBarProps['elevation'];
  };
  disableLogoLink?: boolean;
  buttons?: ButtonProps[];
  logoLink?: string;
}

const ACTIVATION_THRESHOLD = 1;
const DEACTIVATION_THRESHOLD = 0;

const INTERSECTION_OPTIONS = {
  threshold: [ACTIVATION_THRESHOLD, DEACTIVATION_THRESHOLD]
};

const AppBar = ({
  buttons,
  appBar: _appBar,
  disableLogoLink,
  logoLink = '/'
}: AppBarProps) => {
  const { color, stickyColor, elevation, stickyElevation, ...appBar } =
    _appBar || {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isStuck, setIsStuck] = useState(false);
  const [wrapperEl, setWrapperEl] = useState<null | Element>(null);

  const toggleMenuVisibility = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  const intersectionCallback = useCallback(
    ([e]) => {
      if (appBar?.position !== 'sticky') {
        return;
      }
      if (e.intersectionRatio >= ACTIVATION_THRESHOLD) {
        setIsStuck(false);
        return;
      }
      if (e.intersectionRatio <= DEACTIVATION_THRESHOLD) {
        setIsStuck(true);
      }
    },
    [appBar]
  );

  useIntersectionObserver(
    wrapperEl,
    intersectionCallback,
    INTERSECTION_OPTIONS
  );

  return (
    <>
      <div ref={setWrapperEl} />
      <MuiAppBar
        {...appBar}
        elevation={(isStuck ? stickyElevation : elevation) || 0}
        color={isStuck ? stickyColor : color}
        sx={{ width: '100vw', top: 0, transition: 'background-color 150ms' }}
        id={undefined}
      >
        <Toolbar>
          {disableLogoLink ? (
            <HorizontalLogo />
          ) : (
            <Link href={logoLink}>
              <HorizontalLogo />
            </Link>
          )}
          <Hidden mdDown={true}>
            <Box display={'flex'}>
              {buttons?.map(({ href, children, ...buttonProps }, i) => {
                return (
                  <Box key={i} marginLeft={2}>
                    <Link
                      color={'inherit'}
                      underline={'none'}
                      href={(href as string) || ''}
                    >
                      <Button {...buttonProps}>{children}</Button>
                    </Link>
                  </Box>
                );
              })}
            </Box>
          </Hidden>
          <Hidden mdUp={true} xsUp={!buttons?.length}>
            <IconButton
              onClick={toggleMenuVisibility}
              size='large'
              aria-label={'Overflow menu'}
            >
              <SpeedDialIcon
                open={!!anchorEl}
                icon={<MenuIcon />}
                openIcon={<Close />}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={!!anchorEl}
              onClose={toggleMenuVisibility}
            >
              {buttons?.map(({ href, children, ...buttonProps }, i) => {
                return (
                  <MenuItem key={i}>
                    <Link
                      sx={{ width: '100%' }}
                      color={'inherit'}
                      underline={'none'}
                      href={(href as string) || ''}
                    >
                      <Button {...buttonProps} fullWidth={true}>
                        {children}
                      </Button>
                    </Link>
                  </MenuItem>
                );
              })}
            </Menu>
          </Hidden>
        </Toolbar>
      </MuiAppBar>
    </>
  );
};

AppBar.strapiComponent = 'maastr.app-bar';

export { AppBar };
